"use client";
import { ButtonBase, Typography } from "@mui/material";

const ChipAssist = ({
  label,
  disabled,
  elevated,
  StartIcon,
  EndIcon,
  sx = {},
  small,
  ...props
}) => (
  <ButtonBase
    disabled={disabled}
    sx={theme => ({
      height: small ? 24 : 32,
      px: 2,
      borderRadius: theme.shape.sm,
      border: `1px solid ${
        disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.12)
          : theme.palette.outline.color
      }`,
      boxShadow: elevated ? theme.shadows[1] : undefined,
      bgcolor: elevated
        ? disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.12)
          : theme.palette.surface.color
        : "surface.container.low",
      transition: theme.transitions.create("all"),
      "&:hover": {
        opacity: 0.92,
        boxShadow: elevated ? theme.shadows[2] : undefined,
        bgcolor: theme.palette.alpha(theme.palette.surface.on.color, 0.08)
      },
      "&:focus": {
        opacity: 0.88,
        color: theme.palette.surface.on.color
      },
      ...sx
    })}
    {...props}
  >
    {StartIcon ? (
      <StartIcon
        sx={{
          m: -1,
          mr: 1,
          height: 18,
          width: 18,
          color: theme =>
            disabled
              ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
              : theme.palette.primary.color
        }}
      />
    ) : undefined}
    <Typography
      sx={theme => ({
        ...theme.typography.lineClamp(1),
        color: disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
          : theme.palette.surface.on.color,
        textTransform: "capitalize",
        typography: "labelMd"
      })}
    >
      {label}
    </Typography>
    {EndIcon ? (
      <EndIcon
        sx={{
          m: -1,
          mr: 1,
          height: 18,
          width: 18,
          color: theme =>
            disabled
              ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
              : theme.palette.primary.color
        }}
      />
    ) : undefined}
  </ButtonBase>
);

export default ChipAssist;
