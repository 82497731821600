import { useEffect, useState } from "react";
import { Divider, List, Checkbox, Box, IconButton } from "@mui/material";
import {
  ArrowRight as EditIcon,
  AddOutlined as NewIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../../../service/mobx";
import { ListItem } from "../../../List/ListItem";
import { dialogListCrud } from "./ListCrud";
import ToolTip from "../../../Tooltip";

const DialogStar = observer(function DialogStar() {
  const [newState, setNewState] = useState(new Set());
  const { analytics, dialog, snackbar, stars } = useStore();

  useEffect(() => {
    setNewState(new Set(stars.dictionary[dialog.state.entity.id]));
  }, [stars, dialog.state.entity.id, stars.dictionary]);

  useEffect(() => {
    const changed =
      JSON.stringify(
        [...(stars.dictionary[dialog.state.entity.id] ?? [])].sort()
      ) !== JSON.stringify([...newState].sort());

    dialog.set.headlineActions([
      {
        label: "Save",
        disabled: changed === false,
        onClick() {
          stars.list.updateBookmarks(dialog.state.entity, newState);
          dialog.toggle();
          analytics.track.event(`Star ${newState.size ? "Save" : "Unsave"}`, {
            entity: {
              id: dialog.state.entity.id,
              type: dialog.state.entity.__typename
            }
          });
        }
      }
    ]);
  }, [analytics, snackbar, stars, dialog, newState]);

  return (
    <List
      disablePadding
      sx={{ mx: { compact: "-24px !important", expanded: "0 !important" } }}
    >
      <ListItem
        line1="New list"
        onClick={() => {
          const state = { ...dialog.state };

          dialog.configure({
            ...dialogListCrud,
            headline: "New list",
            BackIconCallback() {
              dialog.configure({ ...dialogStar, state });
            }
          });
        }}
        trailing={{ Icon: NewIcon }}
        leading={{ Icon: () => <Box width={42} /> }}
      />
      <Divider />
      {stars.lists.map(({ id, name, description, stars }) => (
        <ListItem
          key={id}
          line1={name}
          line2={`${stars.length} saves`}
          selected={newState.has(id)}
          leading={{
            Icon: () => (
              <Checkbox
                checked={newState.has(id)}
                onClick={event => {
                  event.stopPropagation();

                  if (newState.has(id)) {
                    newState.delete(id);
                  } else {
                    newState.add(id);
                  }
                  setNewState(new Set(newState));
                }}
              />
            )
          }}
          trailing={{
            Icon: () => (
              <ToolTip placement="left" title="Edit list">
                <IconButton
                  onClick={() => {
                    const state = { ...dialog.state };

                    dialog.configure({
                      ...dialogListCrud,
                      headline: "Edit list",
                      state: { id, name, description },
                      BackIconCallback() {
                        dialog.configure({ ...dialogStar, state });
                      }
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </ToolTip>
            )
          }}
        />
      ))}
    </List>
  );
});

export const dialogStar = { headline: "Your lists", Content: DialogStar };
