import { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";

import { useStore, observer } from "../../../../service/mobx";

const ListCrud = observer(function ListCrud() {
  const { analytics, dialog, stars } = useStore();
  const [description, setDescription] = useState(
    dialog.state.description || ""
  );
  const [name, setName] = useState(dialog.state.name || "");
  const [error, setError] = useState();

  useEffect(() => {
    const action = dialog.state.name ? "Save" : "Create";
    const nameChanged =
      dialog.state.name === undefined || name !== dialog.state.name;
    const descriptionChanged =
      (dialog.state.description === undefined && description !== "") ||
      (dialog.state.description !== undefined &&
        dialog.state.description !== description);

    const enabled = !error && !!name && (nameChanged || descriptionChanged);

    dialog.set.headlineActions(
      [
        action === "Create"
          ? undefined
          : {
              label: "Delete",
              onClick() {
                const state = { ...dialog.state };
                const headline = dialog.headline;
                const BackIconCallback = dialog.BackIconCallback;

                dialog.configure({
                  headline: "Are you sure?",
                  BackIconCallback() {
                    dialog.configure({
                      ...dialogListCrud,
                      state,
                      headline,
                      BackIconCallback
                    });
                  },
                  headlineActions: [
                    {
                      label: "Delete",
                      async onClick() {
                        await stars.list.delete(state.id);
                        BackIconCallback();
                        analytics.track.event("List Delete");
                      }
                    }
                  ]
                });
              }
            },
        {
          label: action,
          disabled: enabled === false,
          async onClick() {
            if (action === "Create") {
              await stars.list.create(name, description || undefined);
              analytics.track.event("List Create");
            } else {
              await stars.list.edit(
                dialog.state.id,
                name,
                description || undefined
              );
              analytics.track.event("List Edit");
            }
            dialog.BackIconCallback();
          }
        }
      ].filter(defined => defined)
    );
  }, [analytics, dialog, stars, error, name, description]);

  return (
    <Stack spacing={2}>
      <TextField
        autoFocus
        fullWidth
        required
        label="Name this list"
        error={error !== undefined}
        helperText={error}
        value={name}
        sx={{ mt: 1 }}
        onChange={event => {
          const name = event.target.value.trimStart().replace(/[\s-]+/g, "-");
          const error =
            name.length === 0
              ? "List name cannot be empty"
              : name !== dialog.state.name &&
                stars.lists.some(list => list.name === name)
              ? "List names cannot be reused"
              : undefined;

          setError(error);
          setName(name);
        }}
      />
      <TextField
        fullWidth
        label="Give this list a description"
        value={description}
        onChange={event => setDescription(event.target.value)}
      />
    </Stack>
  );
});

export const dialogListCrud = { headline: "New list", Content: ListCrud };
