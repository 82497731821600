import { Stack, Typography } from "@mui/material";

const ChipInfo = ({ size, label, Icon }) => (
  <Stack useFlexGap direction="row" alignItems="center" spacing={0.5}>
    {Icon ? (
      <Icon
        sx={{
          height: size === "small" ? 12 : 16,
          width: size === "small" ? 12 : 16,
          color: "surface.on.variant"
        }}
      />
    ) : undefined}
    <Typography
      color="surface.on.color"
      variant={
        size === "small" ? "bodySm" : size === "medium" ? "titleSm" : "titleMd"
      }
    >
      {label}
    </Typography>
  </Stack>
);

export default ChipInfo;
