import { Fragment, useState } from "react";
import { Avatar, CardHeader, Box, Stack, Typography } from "@mui/material";
import { OpenInNew as ExternalLinkIcon } from "@mui/icons-material";

import { useStore } from "../../../service/mobx";
import FilledCard from "../Filled";
import { SubHeader } from "../Paper";

const CardExternalSize2 = ({ content, externalType }) => (
  <Card
    sx={{ p: 1 }}
    type={externalType}
    href={content.clean?.url ?? content.url}
  >
    <CardHeader
      avatar={<CardImage src={content.img} />}
      title={content.clean?.title ?? content.title ?? content.node?.title}
      titleTypographyProps={{
        variant: "titleSm",
        color: "surface.on.color",
        sx: theme => theme.typography.lineClamp(2)
      }}
      subheader={
        <>
          <ExternalIconInline />
          {content.publisher}
        </>
      }
      subheaderTypographyProps={{
        variant: "bodySm",
        display: "flex",
        alignItems: "center",
        color: "surface.on.color"
      }}
      sx={{ p: 0 }}
    />
  </Card>
);
const CardExternalPaperSize2 = ({ paper, tone }) => (
  <Card type="paper" href={paper.url} tone={tone}>
    <CardHeader
      title={paper.title}
      titleTypographyProps={{
        variant: "labelLg",
        color: "surface.on.color",
        sx: theme => theme.typography.lineClamp(3)
      }}
      subheader={
        <Stack direction="row" alignItems="center">
          <ExternalIconInline />
          <SubHeader publisher org paper={paper} />
        </Stack>
      }
      subheaderTypographyProps={{
        variant: "bodySm",
        color: "surface.on.color"
      }}
      sx={{ p: 0 }}
    />
  </Card>
);

function CardExternalCodeSize2({ content }) {
  const { utilities } = useStore();
  const { node: repo, official } = content ?? {};
  const github = repo.source === "github";
  const items = [
    official ? (
      <Typography color="primary.color" variant="labelMd">
        Official
      </Typography>
    ) : (
      repo.source
    ),
    repo.language,
    repo.stars
      ? `${utilities.formatNumber(repo.stars)} star${
          repo.stars === 1 ? "" : "s"
        }`
      : undefined,
    repo.forks
      ? `${utilities.formatNumber(repo.forks)} fork${
          repo.forks === 1 ? "" : "s"
        }`
      : undefined,
    repo.likes
      ? `${utilities.formatNumber(repo.likes)} like${
          repo.likes === 1 ? "" : "s"
        }`
      : undefined,
    repo.downloads
      ? `${utilities.formatNumber(repo.downloads)} download${
          repo.downloads === 1 ? "" : "s"
        }`
      : undefined
  ].filter(defined => defined);

  return (
    <Card
      type="repo"
      sx={{ p: 1 }}
      href={github ? repo.url : `https://huggingface.co/${repo.repoID}`}
    >
      <CardHeader
        avatar={<CardImage src={repo.owner?.[0]?.avatar} />}
        title={
          github ? `${repo.owner?.[0]?.username} / ${repo.title}` : repo.repoID
        }
        titleTypographyProps={{
          variant: "titleSm",
          color: "surface.on.color",
          sx: theme => theme.typography.lineClamp(3)
        }}
        subheader={
          <Stack
            useFlexGap
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            width="100%"
          >
            <ExternalIconInline />
            {items.map((content, index, array) => (
              <Fragment key={index}>
                {content}
                {index + 1 === array.length ? undefined : (
                  <Box component="span" mx={0.5}>
                    ·
                  </Box>
                )}
              </Fragment>
            ))}
          </Stack>
        }
        subheaderTypographyProps={{
          variant: "bodySm",
          color: "surface.on.color"
        }}
        sx={{ p: 0 }}
      />
    </Card>
  );
}

export { CardExternalSize2, CardExternalPaperSize2, CardExternalCodeSize2 };

const ExternalIconInline = () => (
  <ExternalLinkIcon
    sx={{ width: 12, height: 12, color: "surface.on.variant", mr: 0.5 }}
  />
);

function Card({
  children,
  href,
  tone = "surface.container.low",
  sx = {},
  type
}) {
  const { analytics } = useStore();

  return (
    <FilledCard
      href={href}
      target="_blank"
      tone={tone}
      sx={sx}
      onClick={() =>
        analytics.track.event("External Link", { type, url: href })
      }
    >
      {children}
    </FilledCard>
  );
}

function CardImage({ src }) {
  const [noImg, setNoImg] = useState(!src); // cover null and undefined

  return noImg ? undefined : (
    <Avatar
      src={src}
      alt="preview"
      variant="rounded"
      imgProps={{
        onError: () => setNoImg(true),
        sx: theme => ({
          maxWidth: 56,
          borderRadius: theme.shape.md.round
        })
      }}
    />
  );
}
