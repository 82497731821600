import { ButtonBase } from "@mui/material";

export default function FilledCard({
  href,
  children,
  disabled,
  raise = true,
  thin = false,
  surface = true,
  tone = "surface.container.highest",
  sx = {},
  ...props
}) {
  //
  return (
    <ButtonBase
      href={href}
      disabled={disabled}
      disableRipple={surface}
      component={href ? undefined : "div"}
      sx={theme => ({
        p: thin ? 1 : 2,
        display: "block",
        cursor: href ? "pointer" : surface ? "default" : undefined,
        bgcolor: disabled ? theme.palette.surface.variant : tone,
        opacity: disabled ? 0.38 : undefined,
        borderRadius: theme.shape.md.round,
        transition: theme.transitions.create("all"),
        "&:hover": {
          opacity: 0.92,
          boxShadow: raise ? theme.shadows[1] : undefined,
          color: theme.palette.surface.on.color
        },
        "&:focus": {
          opacity: 0.88,
          color: theme.palette.surface.on.color
        },
        ...sx
      })}
      {...props}
    >
      {children}
    </ButtonBase>
  );
}
