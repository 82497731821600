"use client";
import { ButtonBase, Typography } from "@mui/material";

import { Check as SelectedIcon } from "@mui/icons-material";
import { ShallowLink } from "../../Link/Shallow";

const ChipFilter = ({
  label,
  selected,
  disabled,
  elevated,
  StartIcon = SelectedIcon,
  EndIcon,
  endIcon,
  href,
  small,
  sx = {},
  ...props
}) => (
  <ButtonBase
    disabled={disabled}
    href={href}
    LinkComponent={href ? ShallowLink : undefined}
    sx={theme => ({
      height: small ? 24 : 32,
      pl: small ? 1 : 1.5,
      // height: 32,
      px: 2,
      textWrap: "nowrap",
      borderRadius: theme.shape.sm,
      border: !disabled && selected ? 0 : 1,
      borderColor: disabled
        ? theme.palette.alpha(theme.palette.surface.on.color, 0.12)
        : selected
        ? "secondary.container"
        : elevated
        ? "surface.container.low"
        : "outline.color",
      boxShadow: elevated ? theme.elevated[1] : undefined,
      bgcolor: elevated
        ? "surface.color"
        : disabled
        ? theme.palette.alpha(theme.palette.surface.on.color, 0.12)
        : selected
        ? "secondary.container"
        : "surface.container.low",
      transition: theme.transitions.create("all"),
      "&:hover": {
        opacity: 0.92,
        backgroundImage: `linear-gradient(${theme.palette.alpha(
          selected
            ? theme.palette.secondary.on.container
            : theme.palette.surface.on.variant,
          0.08
        )}, ${theme.palette.alpha(
          selected
            ? theme.palette.secondary.on.container
            : theme.palette.surface.on.variant,
          0.08
        )})`,
        backgroundBlendMode: "normal",
        boxShadow: selected
          ? theme => theme.elevation[elevated ? 2 : 1]
          : undefined
      },
      "&:focus": {
        opacity: 0.88,
        color: "surface.on.color"
      },
      ...sx
    })}
    {...props}
  >
    {(StartIcon !== SelectedIcon && !!StartIcon) || selected ? (
      <StartIcon
        sx={{
          mr: 1,
          ml: -1,
          height: 18,
          width: 18,
          color: theme =>
            disabled
              ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
              : "surface.on.variant"
        }}
      />
    ) : null}
    <Typography
      textTransform="capitalize"
      sx={theme => ({
        typography: {
          compact: `label${small ? "Sm" : "Md"}`,
          expanded: `label${small ? "Sm" : "Lg"}`
        },
        color: disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
          : selected
          ? "surface.on.variant"
          : "surface.on.color"
      })}
    >
      {label}
    </Typography>
    {endIcon ? (
      endIcon
    ) : EndIcon ? (
      <EndIcon
        label={label}
        sx={{
          ml: 1,
          mr: -1,
          // mx: small ? 0.5 : 1,
          height: 18,
          width: 18,
          color: theme =>
            disabled
              ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
              : selected
              ? "secondary.on.container"
              : "surface.on.variant"
        }}
      />
    ) : undefined}
  </ButtonBase>
);

export default ChipFilter;
