import dynamic from "next/dynamic";
import {
  PlayCircleOutline as VideoIcon,
  TokenOutlined as TaskIcon
} from "@mui/icons-material";

import { CardTaskSize4 } from "../../Card/Task";
import { PaperCardSize2 as PaperCard, PaperCardSize5 } from "../../Card/Paper";
import { PaperIcon } from "../../Icon/Papers";
import { ModelIcon } from "../../Icon/Model";

const CardVideoSize1 = dynamic(() =>
  import("../../Card/External/Video").then(module => module.CardVideoSize1)
);
const CardVideoSize3 = dynamic(() =>
  import("../../Card/External/Video").then(module => module.CardVideoSize3)
);

export const entities = [
  // {
  //   Icon: ModelIcon,
  //   label: "models",
  //   href: "models",
  //   Card: [PaperCard, PaperCardSize3],
  //   entityName: "paper",
  //   query: "searchPapers"
  // },
  {
    Icon: PaperIcon,
    label: "papers",
    href: "papers",
    capital: "Papers",
    Card: [PaperCard, PaperCardSize5],
    entityName: "paper",
    query: "searchPapers"
  },
  {
    Icon: VideoIcon,
    label: "videos",
    href: "videos",
    capital: "Videos",
    Card: [CardVideoSize1, CardVideoSize3],
    entityName: "video",
    query: "searchVideos"
  },
  {
    Icon: TaskIcon,
    label: "tasks",
    href: "tasks",
    capital: "Tasks",
    Card: [CardTaskSize4, CardTaskSize4],
    entityName: "tag",
    query: "searchTasks"
  }
];

export const entityMap = {
  model: {
    Icon: ModelIcon,
    label: "models",
    href: "models",
    entityName: "paper",
    query: "searchPapers"
  },
  paper: {
    Icon: PaperIcon,
    label: "papers",
    href: "papers",
    capital: "Papers",
    Card: [PaperCard, PaperCardSize5],
    entityName: "paper",
    query: "searchPapers"
  },
  video: {
    Icon: VideoIcon,
    label: "videos",
    href: "videos",
    capital: "Videos",
    Card: [CardVideoSize1, CardVideoSize3],
    entityName: "video",
    query: "searchVideos"
  },
  task: {
    Icon: TaskIcon,
    label: "tasks",
    href: "tasks",
    capital: "Tasks",
    entityName: "tag",
    query: "searchTasks"
  }
};
entityMap.papers = entityMap.paper;
entityMap.tasks = entityMap.task;
entityMap.models = entityMap.model;
entityMap.videos = entityMap.video;
