import { useCallback, useState } from "react";
import { useStore } from "../../service/mobx";

export default function useLongPress(
  onLongPress,
  onClick,
  longPressTime = 500
) {
  const { device } = useStore();
  const [timeoutId, setPressTimerId] = useState();
  const startLongPress = useCallback(
    event => {
      setPressTimerId(
        setTimeout(() => {
          if (device.scrolling === false) {
            setPressTimerId();
            onLongPress(event);
          }
        }, longPressTime)
      );
    },
    [device, onLongPress, longPressTime]
  );
  const stopLongPress = useCallback(
    event => {
      if (event.cancelable) {
        event.preventDefault();
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
        setPressTimerId();
        onClick(event);
      }
    },
    [onClick, timeoutId]
  );
  const events = {
    onClick(event) {
      if (event.cancelable) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  return device.scrolling
    ? events
    : {
        ...events,
        onMouseDown: startLongPress,
        onMouseUp: stopLongPress,
        onMouseLeave: stopLongPress,
        onTouchStart: startLongPress,
        onTouchEnd: stopLongPress
      };
}
